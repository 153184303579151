/**
 * サイドバーを持たないページのテンプレート(XDの404)
 */
import React from 'react';
import styled from 'styled-components';
import { graphql, PageProps } from 'gatsby';
import Layout from '@/templates/Layout';
import { Wrapper } from '@/templates/page';
import Seo from '@/components/Seo';

const Inner = styled.div`
  max-width: 880px;
  padding: 50px 80px 60px;
  text-align: center;
  background: #fff;

  @media (max-width: 767px) {
    padding: 50px 20px 50px;
  }

  h1 {
    font-size: 30px;
    font-weight: bold;

    @media (max-width: 767px) {
      font-size: 22px;
    }
  }

  p {
    margin-top: 30px;
    font-size: 17px;
    line-height: 2;

    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 1.9;
    }
  }
`;

const NoSidePage: React.FC<PageProps<GatsbyTypes.NoSidePageQuery>> = ({
  data,
  location,
}) => {
  const { fields, frontmatter, html } =
    data.markdownRemark as GatsbyTypes.MarkdownRemark;

  const { href, origin } = location;

  return (
    <>
      <Seo url={href || (fields?.slug as string)} origin={origin} />
      <Layout fullwidth>
        <Wrapper>
          <Inner>
            <h1>{frontmatter?.title}</h1>
            {/* biome-ignore lint/security/noDangerouslySetInnerHtml: Remarkから生成されたHTMLを直接表示する */}
            <div dangerouslySetInnerHTML={{ __html: html || '' }} />
          </Inner>
        </Wrapper>
      </Layout>
    </>
  );
};

export const query = graphql`
  query NoSidePage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
  }
`;

export default NoSidePage;
